<template>
    <div class="sign">
        <div class="left_area">
            <div class="logo">
                <img :src="env.VUE_APP_NAME ? require('@/assets/images/thrivecoach/logo2.svg') : require('@/assets/images/logo2.png')">
            </div>
            <h2>Sign In</h2>
            <h4>
                Need An Account?
                <router-link :to="{ name: 'Register' }">Get Started!</router-link>
            </h4>
            <Form @submit="handleLogin" :validation-schema="schema" v-slot="{ errors }">
                <div class="group_item">
                    <label class="input_label">E-mail</label>
                    <div class="field_wpr" :class="{ 'has-error': errors.email }">
                        <Field autocomplete="off" name="email" type="email" placeholder="Type your account email… " />
                    </div>
                    <ErrorMessage name="email" class="text-danger" />
                </div>
                <div class="group_item">
                    <label class="input_label">Password</label>
                    <div class="field_wpr" :class="{ 'has-error': errors.password }">
                        <Field autocomplete="off" name="password" type="password" placeholder="Type Password" />
                    </div>
                    <ErrorMessage name="password" class="text-danger" />
                </div>
                <label for="remember" class="checkbox">
                    <Field autocomplete="off" name="remember" type="checkbox" id="remember" hidden value="1" />
                    <span><i class="fas fa-check"></i></span> Remember Me
                </label>
                <button type="submit" class="primary_btn" :disabled="formLoader">
                    <span v-if="formLoader"><i class="fa fa-spin fa-spinner"></i> &nbsp; Signing In</span>
                    <span v-else>Sign In</span>
                </button>
            </Form>
            <div class="forgot">
                <router-link to="/forgot">Forgot Password?</router-link>
            </div>
        </div>
        <div class="right_area">
            <img :src="require('@/assets/images/help-center.svg')">
            <h5>Hey, Coach</h5>
            <p>We are here to help you get coaching clients, and make them raving fans for life!</p>
            <a href="https://thrivecoach.io">thrivecoach.io</a>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import * as yup from 'yup'

    export default {
        name: "Login",

        data () {
            const schema = yup.object({
                email: yup.string().required().email().label('The email'),
                password: yup.string().required().min(6).label('The password'),
            });

            return {
                schema,
                env: {},
            };
        },

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        computed: mapState({
            formLoader: state => state.authModule.formLoader,
        }),

        mounted () {
            const vm = this;

            vm.env = process.env;

            setTimeout(function () {
                const gist = window.gist;

                if (gist && process.env.VUE_APP_ENV == 'production') {
                    gist.chat('shutdown');
                }
            }, 500);
        },

        methods: {
            ...mapActions({
                login: 'authModule/login',
            }),

            handleLogin (params, { setFieldError }) {
                const vm = this;
                params.setFieldError = setFieldError;

                vm.login(params);
            },

            getDomain (url) {
                const link = new URL(url);

                return link.hostname;
            },
        },
    }
</script>
